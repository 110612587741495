import { IconColor, IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import Icons from '@aurora/shared-client/icons';
import type { Message } from '@aurora/shared-generated/types/graphql-schema-types';
import { RsvpResponse } from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import type { OccasionRsvpsQuery, OccasionRsvpsQueryVariables } from '../../../types/graphql-types';
import useTranslation from '../../useTranslation';
import occasionRsvpsQuery from '../OccasionRsvpIndicator/OccasionRsvps.query.graphql';

interface Props {
  /**
   * The occasion for which attendee count should be displayed
   */
  message: Message;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * Display count and text in table format.
   */
  useTable?: boolean;
  /**
   * Set a custom element for attendee count component.
   */
  countAs?: React.ElementType;
  /**
   * Class name(s) to apply to the span element.
   */
  textClassName?: string;
  /**
   * Class name(s) to apply to the counts span element.
   */
  countClassName?: string;
}

/**
 * Occasion attendee count
 *
 * @constructor
 *
 * @author Aditi Agarwal
 */

const OccasionAttendeeCount: React.FC<React.PropsWithChildren<Props>> = ({
  message,
  className,
  useTable = false,
  countAs: Component = 'span',
  countClassName,
  textClassName
}) => {
  const cx = useClassNameMapper();
  const {
    formatMessage,
    loading: textLoading,
    FormattedMessage
  } = useTranslation(EndUserComponent.OCCASION_ATTENDEE_COUNT);
  const { formatNumber } = useIntl();

  const { data: occasionRsvpData, loading: occasionRsvpLoading } = useQueryWithTracing<
    OccasionRsvpsQuery,
    OccasionRsvpsQueryVariables
  >(module, occasionRsvpsQuery, {
    variables: {
      constraints: {
        occasionId: { eq: message.id },
        rsvpResponse: { eq: RsvpResponse.Yes }
      }
    }
  });

  if (textLoading || occasionRsvpLoading) {
    return null;
  }

  const totalCount = occasionRsvpData?.rsvps?.totalCount;

  const attendeeCount = formatNumber(totalCount, { notation: 'compact', compactDisplay: 'short' });

  return attendeeCount ? (
    <div
      className={cx('lia-g-count-wrap', { 'lia-g-count-use-text': useTable }, className)}
      data-testid="OccasionAttendeeCount"
    >
      {useTable ? (
        <FormattedMessage
          id="textTitle"
          values={{
            count: attendeeCount,
            spanText: function renderChunks(chunks): React.ReactNode {
              return <span className={cx('lia-g-count-text', textClassName)}>{chunks}</span>;
            },
            spanCount: function renderCount(): React.ReactNode {
              return (
                <Component className={cx('lia-g-count-number', countClassName)}>
                  {attendeeCount}
                  <span className={cx('sr-only')}>
                    {formatMessage('totalCount', { count: totalCount })}
                  </span>
                </Component>
              );
            }
          }}
        />
      ) : (
        <>
          <Icon icon={Icons.UserIcon} color={IconColor.GRAY_600} size={IconSize.PX_16} />
          {attendeeCount}
          <span className={cx('sr-only')}>
            {formatMessage('totalCount', { count: totalCount })}
          </span>
        </>
      )}
    </div>
  ) : null;
};

export default OccasionAttendeeCount;
