import { IconColor, IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import Icons from '@aurora/shared-client/icons';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import type { MessageViewCountFragment } from '../../../types/graphql-types';
import useTranslation from '../../useTranslation';

interface Props {
  /*
   * The message the View Count belongs to.
   */
  message: MessageViewCountFragment;
  /*
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * Display count and text in table format.
   */
  useTable?: boolean;
  /**
   * Set a custom element for views count component.
   */
  countAs?: React.ElementType;
  /**
   * Class name(s) to apply to the span element.
   */
  textClassName?: string;
  /**
   * Class name(s) to apply to the counts span element.
   */
  countClassName?: string;
}

/**
 * Message View icon and count
 *
 * @constructor
 *
 * @author Arjun Krishna, Willi Hyde
 */

const MessageViewCount: React.FC<React.PropsWithChildren<Props>> = ({
  message,
  className,
  countAs: Component = 'span',
  useTable = false,
  textClassName,
  countClassName
}) => {
  const cx = useClassNameMapper();
  const {
    FormattedMessage,
    formatMessage,
    loading: textLoading
  } = useTranslation(EndUserComponent.MESSAGE_VIEW_COUNT);
  const { formatNumber } = useIntl();

  if (textLoading) {
    return null;
  }

  const {
    metrics: { views }
  } = message;

  const finalViews = formatNumber(views, { notation: 'compact', compactDisplay: 'short' });
  return message?.metrics ? (
    <div
      className={cx('lia-g-count-wrap', { 'lia-g-count-use-text': useTable }, className)}
      data-testid="ViewCount"
    >
      {useTable ? (
        <FormattedMessage
          id="textTitle"
          values={{
            count: finalViews,
            spanText: function renderChunks(chunks): React.ReactNode {
              return <span className={cx('lia-g-count-text ', textClassName)}>{chunks}</span>;
            },
            spanCount: function renderCount(): React.ReactNode {
              return (
                <Component className={cx('lia-g-count-number', countClassName)}>
                  {finalViews}
                  <span className={cx('sr-only')}>{formatMessage('views', { count: views })}</span>
                </Component>
              );
            }
          }}
        />
      ) : (
        <>
          <Icon icon={Icons.ViewsIcon} color={IconColor.GRAY_600} size={IconSize.PX_16} />
          {finalViews}
          <span className={cx('sr-only')}>{formatMessage('views', { count: views })}</span>
        </>
      )}
    </div>
  ) : null;
};

export default MessageViewCount;
