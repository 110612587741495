import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type { OccasionDataFragment } from '../../../types/graphql-types';

interface Props {
  /**
   * The occasion to display the location for.
   */
  occasion: OccasionDataFragment;
  /**
   * Set a custom element for this component.
   */
  as?: React.ElementType;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
}

/**
 * Display location of occasion.
 * @author Aditi Agarwal
 */
const OccasionLocationData: React.FC<React.PropsWithChildren<Props>> = ({
  occasion,
  as: Component = 'div',
  className
}) => {
  const cx = useClassNameMapper();
  const { location } = occasion.occasionData || {};

  return location ? (
    <Component className={cx(className)} data-testid="OccasionLocation">
      {location}
    </Component>
  ) : null;
};

export default OccasionLocationData;
