import type { ApolloQueryResult } from '@apollo/client';
import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import useMutationWithTracing from '@aurora/shared-client/components/useMutationWithTracing';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import { dropdownPopperConfig } from '@aurora/shared-client/helpers/ui/PopperJsHelper';
import type {
  EndUserRouteAndParams,
  MessagePagesAndParams
} from '@aurora/shared-client/routes/endUserRoutes';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import type { MessageSorts, Sort } from '@aurora/shared-generated/types/graphql-schema-types';
import {
  SortDirection,
  UserRepliesSortOrder
} from '@aurora/shared-generated/types/graphql-schema-types';
import type { EndUserPages } from '@aurora/shared-types/pages/enums';
import { EndUserComponent, EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import { getLog } from '@aurora/shared-utils/log';
import React, { useContext, useState } from 'react';
import { Dropdown, useClassNameMapper } from 'react-bootstrap';
import { useUID, useUIDSeed } from 'react-uid';
import type {
  SetRepliesSortOrderSettingsOnUserMutation,
  SetRepliesSortOrderSettingsOnUserMutationVariables
} from '../../../types/graphql-types';
import setRepliesSortOrderSettingsOnUserMutation from '../../users/UserSettingsWidget/PreferencesTab/SetRepliesSortOrderSettingsOnUser.mutation.graphql';
import useTranslation from '../../useTranslation';
import localStyles from './MessageListMenu.module.pcss';
import userRepliesSortOrder from './UserRepliesSortOrder.query.graphql';

const log = getLog(module);

interface MessageListMenuProps<TData> {
  /**
   * The list of sort and filter items to display in the menu.
   */
  items: MessageListMenuItem[];
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * Callback function when an item in the menu is selected.
   *
   * @callback
   * @param item - the selected `MessageListMenuItemDefinition`
   */
  onChange?: (item: MessageListMenuItemDefinition) => Promise<void | ApolloQueryResult<TData>>;
  /**
   * The default selected item. This will default to the first item passed in the
   * `items` parameter if not explicitly specified.
   */
  defaultItem: MessageListMenuItem;
  /**
   * Set a custom element for this component.
   */
  as?: React.ElementType;
  /**
   * The name of the query parameter that is updated when an item is selected from the menu.
   */
  queryParameterName?:
    | EndUserQueryParams.MESSAGE_LIST_MENU_QUERY_PARAMETER
    | EndUserQueryParams.SORT_MENU_QUERY_PARAMETER;
  /**
   * Align the menu to the right side of the Dropdown toggle.
   */
  alignRight?: boolean;
}

export enum MessageListMenuItem {
  POST_TIME_ASC = 'postTimeAsc',
  POST_TIME_DESC = 'postTimeDesc',
  KUDOS_SUM_WEIGHT_ASC = 'kudosSumWeightAsc',
  KUDOS_SUM_WEIGHT_DESC = 'kudosSumWeightDesc'
}

export interface MessageListMenuItemDefinition {
  sorts: MessageSorts;
}

const directionAsc: Sort = {
  direction: SortDirection.Asc
};

const directionDesc: Sort = {
  direction: SortDirection.Desc
};

export const messageListMenuItemMap: Record<MessageListMenuItem, MessageListMenuItemDefinition> = {
  [MessageListMenuItem.POST_TIME_ASC]: {
    sorts: {
      postTime: directionAsc
    }
  },
  [MessageListMenuItem.POST_TIME_DESC]: {
    sorts: {
      postTime: directionDesc
    }
  },
  [MessageListMenuItem.KUDOS_SUM_WEIGHT_ASC]: {
    sorts: {
      kudosSumWeight: directionAsc
    }
  },
  [MessageListMenuItem.KUDOS_SUM_WEIGHT_DESC]: {
    sorts: {
      kudosSumWeight: {
        direction: SortDirection.Desc,
        order: 0
      },
      postTime: {
        direction: SortDirection.Asc,
        order: 1
      }
    }
  }
};

/**
 * Displays a configurable list of sorts and filters that can be applied to a list of messages.
 * Currently only supports sorting by post time and kudos count; other sorts and filters will
 * be added as needed.
 *
 * @constructor
 *
 * @author Adam Ayres
 */
// eslint-disable-next-line @typescript-eslint/comma-dangle
const MessageListMenu = <TData,>({
  items,
  as,
  className,
  onChange = (): Promise<void> => null,
  defaultItem,
  queryParameterName = EndUserQueryParams.MESSAGE_LIST_MENU_QUERY_PARAMETER,
  alignRight = false
}: MessageListMenuProps<TData>): React.ReactElement => {
  const cx = useClassNameMapper(localStyles);
  const {
    formatMessage,
    loading: textLoading,
    FormattedMessage
  } = useTranslation(EndUserComponent.MESSAGE_LIST_MENU);
  const { isAnonymous } = useRegistrationStatus();
  const { authUser } = useContext(AppContext);
  const uidSeed = useUIDSeed();
  const uid = useUID();
  const { router } = useEndUserRoutes();

  const sortOrderMapping = {
    [MessageListMenuItem.KUDOS_SUM_WEIGHT_DESC]: UserRepliesSortOrder.Likes,
    [MessageListMenuItem.POST_TIME_ASC]: UserRepliesSortOrder.PublishTime,
    [MessageListMenuItem.POST_TIME_DESC]: UserRepliesSortOrder.ReversePublishTime
  };

  const [setRepliesSortOrderSettingsOnUserMutate] = useMutationWithTracing<
    SetRepliesSortOrderSettingsOnUserMutation,
    SetRepliesSortOrderSettingsOnUserMutationVariables
  >(module, setRepliesSortOrderSettingsOnUserMutation);

  const [selectedItem, setSelectedItem] = useState<MessageListMenuItem>(defaultItem);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  if (textLoading) {
    return null;
  }

  async function selectHandler(item: MessageListMenuItem): Promise<void> {
    const itemDefinition = messageListMenuItemMap[item];
    setSelectedItem(item);
    if (!isAnonymous) {
      const { errors: repliesSortOrderErrors } = await setRepliesSortOrderSettingsOnUserMutate({
        variables: {
          userId: authUser.id,
          settingsInput: {
            sortOrder: sortOrderMapping[item]
          }
        },
        refetchQueries: [
          {
            query: userRepliesSortOrder
          }
        ]
      });

      if (repliesSortOrderErrors?.length > 0) {
        log.error(repliesSortOrderErrors, 'Error changing message sort or filter');
        return;
      }
    }

    onChange(itemDefinition)
      .finally(async () => {
        await router.replaceRoute<EndUserRouteAndParams<EndUserPages>>(
          router.getCurrentPageName(),
          router.getPathParams<MessagePagesAndParams>(),
          {
            [queryParameterName]: item
          },
          { scroll: false }
        );
      })
      .catch(error => log.error(error, 'Error changing message sort or filter'));
  }
  function renderMenuItem(item: MessageListMenuItem): React.ReactElement {
    return (
      <li role="none" key={item}>
        <Dropdown.Item
          id={`lia-menu-${item}`}
          aria-selected={item === selectedItem}
          role="option"
          active={item === selectedItem}
          onSelect={async (): Promise<void> => selectHandler(item)}
          className={cx('position-relative')}
          data-testid={`MessageMenuItem.${item}`}
        >
          {formatMessage(item)}
        </Dropdown.Item>
      </li>
    );
  }
  /**
   * This function handles the toggle event of the dropdown
   */
  const onToggle = (
    isOpen: boolean,
    event: React.SyntheticEvent<Dropdown>,
    metadata: {
      source: 'select' | 'click' | 'rootClose' | 'keydown';
    }
  ) => {
    if (metadata.source === 'click') {
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };

  return (
    <Dropdown
      as={as}
      className={cx(className)}
      show={showDropdown}
      onToggle={onToggle}
      onKeyDown={event => {
        if (event.key === 'Tab') {
          setShowDropdown(false);
        }
      }}
    >
      <Dropdown.Toggle
        as={Button}
        variant={ButtonVariant.LINK}
        id={uidSeed('message-list-menu')}
        className={cx('lia-g-dropdown-toggle')}
        data-testid="MessageListToggle"
        role="combobox"
        aria-autocomplete="list"
        aria-activedescendant={`lia-menu-${selectedItem}`}
        aria-controls={`listbox-${uid}`}
        aria-live="assertive"
        aria-atomic="true"
      >
        <FormattedMessage
          id={'sortedBy.item'}
          values={{
            itemName: selectedItem,
            style: function renderChunks(chunks): React.ReactNode {
              return <span className={cx('sr-only')}>{chunks}</span>;
            }
          }}
        />
      </Dropdown.Toggle>
      <Dropdown.Menu
        id={`listbox-${uid}`}
        className={cx({ 'lia-g-popper-right': alignRight })}
        popperConfig={dropdownPopperConfig}
        align={alignRight ? 'right' : 'left'}
        renderOnMount
        data-testid="MessageListMenu"
      >
        <Dropdown.Header id={`menuTitle-${uid}`}>{formatMessage('sortTitle')}</Dropdown.Header>
        <ul className={cx('lia-sort-list')} role="listbox" aria-labelledby={`menuTitle-${uid}`}>
          {items.map(item => renderMenuItem(item))}
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MessageListMenu;
