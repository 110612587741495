import useCrossOriginAttribute from '@aurora/shared-client/components/useCrossOriginAttribute';
import UrlHelper from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlHelper';
import type { ReactElement } from 'react';
import React from 'react';
import { Image, useClassNameMapper } from 'react-bootstrap';
import { MessageViewVariant } from '../../../types/enums';
import type {
  MessagePreviewVideoFragment,
  PreviewVideoFragment
} from '../../../types/graphql-types';
import localStyles from '../MessagePreviewVideo/MessagePreviewVideo.module.pcss';

interface Props {
  /**
   * The message the video belong to.
   */
  message: MessagePreviewVideoFragment;
  /**
   * The message view variant
   */
  variant: MessageViewVariant;
  /**
   * The fallback component to be rendered when the message has no associated images.
   */
  fallback?: React.ComponentType<React.PropsWithChildren<unknown>>;
}
const MessagePreviewVideo: React.FC<React.PropsWithChildren<Props>> = ({
  message,
  variant,
  fallback: FallbackComponent = null
}) => {
  const cx = useClassNameMapper(localStyles);
  const crossOrigin = useCrossOriginAttribute();
  let videoPlayerIconClass = null;
  let videoImagePreviewClass = null;
  if (variant === MessageViewVariant.CARD || variant === MessageViewVariant.INLINE) {
    videoPlayerIconClass = `lia-video-player-icon-${variant}`;
    videoImagePreviewClass = `lia-img-preview-${variant}`;
  }

  const previewVideo: PreviewVideoFragment = message?.videos?.edges[0]?.node;

  const finalVideoUrl: string = previewVideo?.videoTag?.thumbnail
    ? UrlHelper.adjustImageServerPageUrl(
        previewVideo?.videoTag?.thumbnail,
        { width: 300, height: 400 },
        false
      )
    : null;

  const renderPreviewVideoThumbnail = (): ReactElement => {
    return previewVideo?.videoTag?.thumbnail !== null ||
      previewVideo?.videoTag?.thumbnail === 'undefined' ? (
      <div className={cx('lia-preview-wrapper')} data-testid="messagePreviewVideo">
        <Image
          className={cx(videoImagePreviewClass)}
          src={finalVideoUrl}
          alt={previewVideo?.videoTag?.title}
          crossOrigin={crossOrigin(finalVideoUrl)}
        />
        <span className={cx(videoPlayerIconClass)} />
      </div>
    ) : (
      <div className={cx('lia-image-processing-preview')}>
        <span className={cx(videoPlayerIconClass)} />
      </div>
    );
  };

  return previewVideo ? (
    renderPreviewVideoThumbnail()
  ) : FallbackComponent ? (
    <FallbackComponent />
  ) : null;
};

export default MessagePreviewVideo;
