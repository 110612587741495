import { IconColor, IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import Icons from '@aurora/shared-client/icons';
import type { ConversationStyle } from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import type { MessageRepliesCountFragment } from '../../../types/graphql-types';
import useTranslation from '../../useTranslation';

interface Props {
  /**
   * The message the replies belong to.
   */
  message: MessageRepliesCountFragment;
  /**
   * Display the icon after the replies number.
   */
  isIconLast?: boolean;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * Display count and text in table format.
   */
  useTable?: boolean;
  /**
   * Set a custom element for replies count component.
   */
  countAs?: React.ElementType;
  /**
   * Class name(s) to apply to the span element.
   */
  textClassName?: string;
  /**
   * Class name(s) to apply to the counts span element.
   */
  countClassName?: string;
  /**
   * The conversation style of the message to which the replies belongs to.
   */
  conversationStyle?: ConversationStyle;
}

/**
 * Message replies icon and count.
 *
 * @author Jonathan Bridges
 */
const MessageRepliesCount: React.FC<React.PropsWithChildren<Props>> = ({
  message,
  isIconLast = false,
  className,
  useTable = false,
  countAs: Component = 'span',
  textClassName,
  countClassName,
  conversationStyle = ''
}) => {
  const cx = useClassNameMapper();
  const {
    FormattedMessage,
    formatMessage,
    loading: textLoading
  } = useTranslation(EndUserComponent.MESSAGE_REPLIES_COUNT);
  const { formatNumber } = useIntl();

  if (textLoading) {
    return null;
  }

  const { repliesCount } = message;

  const finalCount = formatNumber(repliesCount, { notation: 'compact', compactDisplay: 'short' });

  return (
    <div
      className={cx('lia-g-count-wrap', { 'lia-g-count-use-text': useTable }, className)}
      data-testid="messageRepliesCount"
    >
      {useTable ? (
        <FormattedMessage
          id="textTitle"
          values={{
            count: repliesCount,
            conversationStyle: conversationStyle,
            spanText: function renderChunks(chunks): React.ReactNode {
              return <span className={cx('lia-g-count-text ', textClassName)}>{chunks}</span>;
            },
            spanCount: function renderCount(): React.ReactNode {
              return (
                <Component className={cx('lia-g-count-number', countClassName)}>
                  {finalCount}
                  <span className={cx('sr-only')}>
                    {formatMessage('comments', { count: repliesCount })}
                  </span>
                </Component>
              );
            }
          }}
        />
      ) : (
        <>
          <Icon
            icon={Icons.CommentIcon}
            color={IconColor.GRAY_600}
            size={IconSize.PX_16}
            className={cx({ 'order-1': isIconLast })}
          />
          {repliesCount}
          <span className={cx('sr-only')}>
            {formatMessage('comments', { count: repliesCount })}
          </span>
        </>
      )}
    </div>
  );
};

export default MessageRepliesCount;
