import useDateTime from '@aurora/shared-client/components/useDateTime';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type { OccasionTimeFragment } from '../../../types/graphql-types';
import usePreferredTimezone from '@aurora/shared-client/components/usePreferredTimezone';
interface Props {
  /**
   * The occasion to display time for.
   */
  occasion: OccasionTimeFragment;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
}

/**
 * Display time of occasion.
 * @author Aditi Agarwal
 */
const OccasionTime: React.FC<React.PropsWithChildren<Props>> = ({ occasion, className }) => {
  const cx = useClassNameMapper();
  const { formatAbsoluteDateTimeForOccasion } = useDateTime();
  const { startTime } = occasion || {};

  const { data: timezoneData, loading: timezoneLoading } = usePreferredTimezone();

  const userPreferredTimeZone = timezoneData?.self.profileSettings.timeZone?.value;

  if (timezoneLoading) {
    return null;
  }

  return startTime ? (
    <span
      className={cx(className)}
      data-testid="occasionTime"
      suppressHydrationWarning
      title={formatAbsoluteDateTimeForOccasion(startTime, userPreferredTimeZone)}
    >
      {formatAbsoluteDateTimeForOccasion(startTime, userPreferredTimeZone)}
    </span>
  ) : null;
};

export default OccasionTime;
