import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { removeExtraSpaces } from '../../../helpers/messages/MessageHelper/MessageHelper';
import type { MessageTeaserFragment } from '../../../types/graphql-types';

interface Props {
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * Set a custom element for this component.
   */
  as?: React.ElementType;
  /**
   * The teaser content to be displayed.
   */
  teaserContent: MessageTeaserFragment;
}

/**
 * Message Teaser.
 * @author Aditi Agarwal
 */
const MessageTeaser: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  as: Component = 'div',
  teaserContent
}) => {
  const cx = useClassNameMapper();
  const { teaser } = teaserContent;
  const teaserHtml = removeExtraSpaces(teaser);

  return teaser ? (
    <Component
      data-testid="MessageTeaser"
      className={cx(className)}
      dangerouslySetInnerHTML={teaser ? { __html: teaserHtml } : null}
    />
  ) : null;
};

export default MessageTeaser;
