import type { OccasionType as OccasionTypeEnum } from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import useTranslation from '../../useTranslation';
import localStyles from './OccasionType.module.pcss';

interface Props {
  /**
   * The occasion type to be displayed
   */
  occasionType: OccasionTypeEnum;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
}

/**
 * Occasion type
 * @author Elamaran P
 */
const OccasionType: React.FC<React.PropsWithChildren<Props>> = ({ occasionType, className }) => {
  const cx = useClassNameMapper(localStyles);
  const { formatMessage, loading: textLoading } = useTranslation(EndUserComponent.OCCASION_TYPE);

  if (textLoading) {
    return null;
  }

  return occasionType ? (
    <div className={cx('lia-type', className)} data-testid="occasionType" suppressHydrationWarning>
      {formatMessage(occasionType)}
    </div>
  ) : null;
};

export default OccasionType;
