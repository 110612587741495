import type { AppContextInterface } from '@aurora/shared-client/components/context/AppContext/AppContext';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import type {
  Message,
  OccasionTopicMessage
} from '@aurora/shared-generated/types/graphql-schema-types';
import { RsvpResponse } from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React, { useContext } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type { OccasionRsvpsQuery, OccasionRsvpsQueryVariables } from '../../../types/graphql-types';
import useTranslation from '../../useTranslation';
import occasionRsvpsQuery from './OccasionRsvps.query.graphql';

interface Props {
  /**
   * The message for which rsvp indicator has to be displayed.
   */
  message: Message;
  /**
   * Set a custom element for this component.
   */
  as?: React.ElementType;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
}

/**
 * Display rsvp response for an occasion.
 * @author Aditi Agarwal
 */
const OccasionRsvpIndicator: React.FC<React.PropsWithChildren<Props>> = ({
  message,
  as: Component = 'div',
  className
}) => {
  const cx = useClassNameMapper();
  const { authUser } = useContext<AppContextInterface>(AppContext);
  const { isAnonymous } = useRegistrationStatus();
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.OCCASION_RSVP_INDICATOR
  );

  const { data: occasionRsvpData, loading: occasionRsvpLoading } = useQueryWithTracing<
    OccasionRsvpsQuery,
    OccasionRsvpsQueryVariables
  >(module, occasionRsvpsQuery, {
    variables: {
      constraints: {
        occasionId: { eq: message.id },
        userId: { eq: authUser?.id }
      }
    },
    skip: isAnonymous
  });

  if (occasionRsvpLoading || textLoading) {
    return null;
  }

  const rsvpResponse = occasionRsvpData?.rsvps?.edges?.map(rsvp => rsvp?.node?.rsvpResponse);
  const endTime = new Date((message as OccasionTopicMessage)?.occasionData?.endTime).getTime();
  const currentTime = Date.now();

  return rsvpResponse?.length > 0 && rsvpResponse[0] === RsvpResponse.Yes ? (
    <Component className={cx(className)} data-testid="OccasionRsvpIndicator">
      {currentTime > endTime ? formatMessage('Attended') : formatMessage('Attending')}
    </Component>
  ) : null;
};

export default OccasionRsvpIndicator;
