import type {
  PreferredTimezoneQuery,
  PreferredTimezoneQueryVariables
} from '@aurora/shared-generated/types/graphql-types';
import timezoneListQuery from './form/DatetimeCustomEditor/PreferredTimezone.query.graphql';
import useQueryWithTracing from './useQueryWithTracing';
import type { ExtendedQueryResult } from './useQueryWithTracing';

/**
 * Function to return preferred timezone for the user
 *
 * @returns Preferred timezone for the user
 */
export default function usePreferredTimezone(): ExtendedQueryResult<
  PreferredTimezoneQuery,
  PreferredTimezoneQueryVariables
> {
  return useQueryWithTracing<PreferredTimezoneQuery, PreferredTimezoneQueryVariables>(
    module,
    timezoneListQuery
  );
}
